<template>
  <v-card flat class="pt-2">
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6" class="py-0">
          <strong>{{ $t(option.key) }}</strong>
          <p class="text-justify">
            {{ option.description }}
          </p>
        </v-col>

        <v-col cols="12" md="6" class="py-0">
          <v-switch
            v-if="option.input_type === 'switch'"
            v-model="model"
            :loading="loading"
            :messages="msg"
            @change="save"
          ></v-switch>
          <ng-text
            v-if="option.input_type === 'text'"
            :filled="false"
            :dense="true"
            @blur="save"
            :loading="loading"
            :messages="msg"
            v-model="model"
          ></ng-text>
          <ng-number
            v-if="option.input_type === 'number'"
            :filled="false"
            :dense="true"
            @blur="save"
            :messages="msg"
            :loading="loading"
            v-model="model"
          ></ng-number>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
  </v-card>
</template>
<script>
// import NextMixinApiUpdate from "@/components/mixins/NextMixinApiUpdate";
import { isUndefined,isNull } from "lodash";

export default {
  props: ["option", "config"],
  data() {
    return {
      loading: false,
      msg: "",
      model: null,
    };
  },
  watch: {
    config(value) {
      if (!this.model && this.option.hasOwnProperty("default")) {
        this.model = this.option.default;
        this.$emit("change:value", this.model, this.option.key);
      }
      if (value) {
        if ( !isUndefined(value[this.option.key]) && !isNull(value[this.option.key])) {
          this.model = value[this.option.key];
          this.$emit("change:value", this.model, this.option.key);
        }
      }
    },
  },
  methods: {
    save() {
      this.$emit("change:value", this.model, this.option.key);
    },
  },
};
</script>