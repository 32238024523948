<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <ValidationObserver v-slot="{ invalid }" ref="form">
      <v-card :key="key">
        <v-app-bar color="orange">
          <v-toolbar-title class="text-h6 white--text pl-0">
            {{ $t("tables") }} - Solo visualización
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            icon
            @click="$emit('close')"
            :title="$t('Cancel')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>
        <v-card-text v-if="items < 1" class="text-center py-5">
          <img src="/assets/chair.png" alt="" />
          <h2 class="red--text">¡No hay mesas disponibles!</h2>
          <p>En este momento no hay mesas disponibles para reservar</p>
        </v-card-text>
        
        <v-card-text style="max-height: 60vh; overflow-y: auto" v-else>
          <v-tabs
            v-model="tab"
            background-color="orange lighten-5"
            grow
            icons-and-text
            show-arrows
          >
            <v-tabs-slider color="orange lighten-2"></v-tabs-slider>

            <v-tab
              :href="'#zone_' + item.id"
              class="transparent orange--text"
              v-for="item in items"
              :key="'vtab_' + item.id"
            >
              {{ item.name }}
              <v-icon color="orange">mdi-store</v-icon>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            class="pa-5"
            style="max-height: 60vh; overflow-y: auto"
          >
            <v-tab-item
              :value="'zone_' + zone.id"
              :id="'zone_' + zone.id"
              v-for="zone in items"
              :key="'vtab_zone' + zone.id"
            >
              <v-row class="pa-1" no-gutters>
                <v-col
                  class="text-center pa-1"
                  md="3"
                  sm="4"
                  v-for="table in zone.tables"
                  :key="'table_' + table.id"
                >
                  <v-card
                    :title="table.name"
                    outline
                    class="mx-auto pt-2 grid_table busy red lighten-4"
                    v-if="table.busy"
                  >
                    <div class="text-center font-weight-bold">
                      <v-chip color="red lighten-2" dark>
                        {{ $t("busy") }}
                      </v-chip>
                    </div>
                    <img src="/assets/chair.png" alt="" />
                    <v-card-title class="justify-center pa-1">
                      <span class="subtitle-1">{{ table.name }}</span>
                    </v-card-title>
                  </v-card>

                  <v-card
                    :title="table.name"
                    outline
                    class="mx-auto pt-2 grid_table"
                    v-else
                  >
                    <div class="text-center font-weight-bold">
                      <v-chip color="blue lighten-2" dark>

                        <span >{{ $t("available") }}</span>
                      </v-chip>
                    </div>
                    <img src="/assets/chair.png" alt="" />
                    <v-card-title class="justify-center pa-1">
                      <span class="subtitle-1">{{ table.name }}</span>
                    </v-card-title>
                  </v-card>
                </v-col>
                <template v-if="invalid"></template>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </ValidationObserver>
  </v-dialog>
</template>
<style>
.bg_modal {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% /
    cover no-repeat;
}
</style>
<script>
import { isUndefined } from "lodash";

export default {
  data: () => ({
    key: 0,
    items: [],
    table: null,
    tab: null,
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    order: Object,
  },
  watch: {
    dialog(val) {
      if (!isUndefined(val) && val) {
        this.getTables();
        this.table = this.order.getNameTable();
      }
    },
  },
  mounted() {},
  methods: {
    selected() {
      const obj = JSON.parse(JSON.stringify(this.item));
      this.clear();
      this.searched = false;
      this.$emit("selected", obj);
      this.key++;
    },
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
      this.item = JSON.parse(JSON.stringify(DEFAULT_ITEM));
    },
    setTable(item) {
      this.$pos.setNameTable(this.order, item.name);
      this.$pos.setIsOnTable(this.order, true);
      this.$emit("cancel");
    },
    isSelectedOrder(item) {
      if (item.name == this.table) {
        return true;
      }
      return false;
    },
    async getTables() {
      /*const tables = await this.$db.getTables();
      this.items = tables;*/
      const inst = await this.$db.getInstallation();
      this.$http
        .get("api/v1/store-tables/" + inst.subsidiary_id)
        .then((response) => {
          this.items = response.items;
          console.log(response);
        })
        .catch((error) => {});
    },
  },
};
</script>